import { boolean } from "drizzle-orm/pg-core";
import posthog from "posthog-js";
import { composeLogbeatEvent } from "./logbeatEventComposer";
import axios from 'axios';
const logbeatWebhookURL = "https://app.logbeat.com/public/webhook/rm07vPolIGgcRrKUZXsKlsAQ8d21VoC19y0glV2D0lB";
const discordWebhookURL = "https://discord.com/api/webhooks/1330019349129465917/JxwgpgpVJ4TfXkg87GzZHz1rZDIAgtTYZE3iHhRR9yk0kyNCb4pfv76n3CJ5NYsJ-bWK";
export function triggerAnalyticsEvent(
  eventTitle: AnalyticsEventTypes,
  data?: object,
  type?: string
) {
  // Only capture analytics in production environment
  if (process.env.NODE_ENV === 'production') {
  // if (true) {
    // if (false) {
    try {
      // Only send specific events to Discord
      const myDiscordEvents = [
        'create_new_video',
        'render_video_done',
        'upgrade_from_free',
        'url_to_video_parse_success',
        'url_to_video_process_success',
        'url_to_video_complete',
        'create_new_blank_video'
      ];
      
      // Send all error events (events ending with '_error') or specific events to Discord
      const shouldSendToDiscord = eventTitle.toLowerCase().endsWith('_error') || 
        myDiscordEvents.includes(eventTitle.toLowerCase());

      // Send to Discord webhook only for specified events
      if (discordWebhookURL && shouldSendToDiscord) {
        const message = {
          content: `**${eventTitle}**`,
          embeds: [{
            title: 'Event Details',
            description: data ? JSON.stringify(data, null, 2) : 'No additional data',
            timestamp: new Date().toISOString()
          }]
        };

        axios.post(discordWebhookURL, message, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          console.log('Successfully sent to Discord:', message);
        })
        .catch(error => {
          console.error('Error sending to Discord:', error);
        });
      }

      // Send to Logbeat webhook (all events)
      if (logbeatWebhookURL) {
        const logbeatMessage = composeLogbeatEvent(eventTitle, data);
        // console.log("logbeatMessage: ", logbeatMessage);
        axios.post(logbeatWebhookURL, logbeatMessage, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          // console.log('Successfully sent to Logbeat:', logbeatMessage);
        })
        .catch(error => {
          console.error('Error sending to Logbeat:', error);
        });
      }
    } catch (error) {
      console.error('Error capturing analytics event:', error);
    }

    //   if (logbeatWebhookURL) {
    //     const logbeatMessage = composeLogbeatEvent(eventTitle, data);
    //     // console.log("logbeatMessage: ", logbeatMessage);
    //     fetch(logbeatWebhookURL, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(logbeatMessage)
    //     })
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       console.log('Successfully sent to Logbeat:', logbeatMessage);
    //     })
    //     .catch(error => {
    //       console.error('Error sending to Logbeat:', error);
    //     });
    //   }
    // } catch (error) {
    //   console.error('Error capturing analytics event:', error);
    // }
  }
}

export enum AnalyticsEventTypes {
  LOGIN_WITH_EMAIL = "login_with_email",
  LOGIN_WITH_EMAIL_ERROR = "login_with_email_error",
  AUTHORIZE_WITH_OAUTH = "authorize_with_oauth",
  AUTHORIZE_WITH_OAUTH_ERROR = "authorize_with_oauth_error",
  LOGOUT = "logout",
  SIGNUP = "signup",
  SIGNUP_ERROR = "signup_error",
  FORGOT_PASSWORD_REQUEST = "forgot_password_request",
  FORGOT_PASSWORD_ERROR = "forgot_password_error",
  CREATE_NEW_VIDEO = "create_new_video",
  CREATE_NEW_VIDEO_ERROR = "create_new_video_error",
  DELETE_VIDEO = "delete_video",
  DELETE_VIDEO_ERROR = "delete_video_error",
  CLONE_VIDEO = "clone_video",
  CLICK_PREVIEW_TEMPLATE = "click_preview_template",
  CLICK_CREATE_FROM_THIS_TEMPLATE = "click_create_from_this_template",
  UPGRADE_FROM_FREE = "upgrade_from_free",
  UPGRADE_FROM_FREE_ERROR = "upgrade_free_error",
  CLICK_RENDER_VIDEO = "click_render_video",
  RENDER_VIDEO_DONE = "render_video_done",
  RENDER_VIDEO_ERROR = "render_video_error",
  CHANGE_ASPECT_RATIO = "change_aspect_ratio",
  CHANGE_ACCENT_COLOR = "change_accent_color",
  CHANGE_BACKGROUND_COLOR = "change_accent_color",
  CHANGE_FONT = "change_font",
  CLICK_DARK_MODE = "click_dark_mode",
  CLICK_HOME_SCREEN = "click_home_screen",
  CLICK_SEE_ALL_VIDEOS = "click_see_all_videos",
  SWITCH_PLAN = "switch_plan",
  CANCEL_PLAN = "cancel_plan",
  SWITCH_PLAN_ERROR = "switch_plan_error",
  CANCEL_PLAN_ERROR = "cancel_plan_error",
  UPGRADE_PLAN = "upgrade_plan",
  DOWNGRADE_PLAN = "downgrade_plan",
  CHANGE_EMAIL = "change_email",
  ADD_FRAME = "add_frame",
  CLICK_INSERT_FRAME = "click_insert_frame",
  ADD_NEW_FRAME_TO_VIDEO = "add_new_frame_to_video",
  UPDATE_VIDEO = "update_video",
  UPDATE_VIDEO_ERROR = "update_video_error",
  CLICK_EDIT_VIDEO = "click_edit_video",
  CLICK_DELETE_FRAME = "click_delete_frame",
  CLICK_USER_SETTINGS_TAB = "click_user_settings_tab",
  CLICK_ACCOUNT_DROPDOWN = "click_account_dropdown",
  VISIT_PLANS_PAGE = "visit_plans_page",
  SET_VIDEO_TITLE = "set_video_title",
  PASSWORD_RESET_SUCCESS = "password_reset_success",
  PASSWORD_RESET_ERROR = "password_reset_error",
  URL_TO_VIDEO_START = "url_to_video_start",
  URL_TO_VIDEO_PARSE_SUCCESS = "url_to_video_parse_success",
  URL_TO_VIDEO_PARSE_ERROR = "url_to_video_parse_error",
  URL_TO_VIDEO_PROCESS_SUCCESS = "url_to_video_process_success",
  URL_TO_VIDEO_PROCESS_ERROR = "url_to_video_process_error",
  URL_TO_VIDEO_COMPLETE = "url_to_video_complete",
  URL_TO_VIDEO_RANDOM_URL = "url_to_video_random_url",
  CREATE_NEW_BLANK_VIDEO = "create_new_blank_video",
  CREATE_NEW_BLANK_VIDEO_ERROR = "create_new_blank_video_error",
}
