import React, { CSSProperties, useMemo } from "react";
import { AbsoluteFill, interpolate } from "remotion";
import { EditableFrameWrapper } from "../../../FrameContentEditors/EditableFrameWrapper";
import { BrowserWindowWithVideo } from "./BrowserWindowWithVideo";
import { USPtext } from "./USPtext";
import { USPtitle } from "./USPtitle";
import { getCommonStyles } from "../../../remotionHelpers/styleUtils";
import { findBackgroundColorObjectByID } from "../../../remotionHelpers/findBackgroundColorObjectByID";
import SideFeatures from "./SideFeatures";

// magic number: the time when the browser animation 2 starts
// we set this manually to prevent extra coding.
// easier to just set and see rather than do extra math.
export const FEATURES_IN_TRANSITION_FRAME = 110;

export function Frame1Index({
  frameData,
  displayAnimations = false,
  isEditable = false,
  editorState,
  currentRemotionFrame = 0,
}) {
  const content = frameData.content || {};
  const { durationInFrames } = frameData;
  const commonStyles = getCommonStyles(editorState);
  const bgColorObject = findBackgroundColorObjectByID(
    editorState.styles.backgroundColorID
  );
  const aspectRatio = editorState.aspectRatio;
  const wrapperStyle = useMemo(
    () => ({
      display: aspectRatio === "vertical" ? "grid" : "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
      paddingLeft: "10em",
      alignContent: aspectRatio === "vertical" ? "space-evenly" : undefined,
    }),
    []
  );
  const titleContainerStyle: CSSProperties = useMemo(
    () => ({
      maxWidth: "41em",
      marginRight: "5em",
      opacity: displayAnimations
        ? interpolate(
            currentRemotionFrame,
            [FEATURES_IN_TRANSITION_FRAME - 5, FEATURES_IN_TRANSITION_FRAME],
            [1, 0],
            {
              extrapolateRight: "clamp",
            }
          )
        : 1,
      visibility:
        displayAnimations &&
        currentRemotionFrame >= FEATURES_IN_TRANSITION_FRAME
          ? "hidden"
          : "visible",
    }),
    [displayAnimations, currentRemotionFrame]
  );
  const sideFeaturesStyle: CSSProperties = useMemo(
    () => ({
      position: "absolute",
      left: "5%",
      top: aspectRatio === "vertical" ? "30%" : "50%",
      transform: "translateY(-50%)",
      zIndex:
        displayAnimations && currentRemotionFrame < FEATURES_IN_TRANSITION_FRAME
          ? -1
          : 10,
      opacity:
        displayAnimations && currentRemotionFrame < FEATURES_IN_TRANSITION_FRAME
          ? 0
          : 1,
    }),
    [displayAnimations, currentRemotionFrame]
  );

  return (
    <EditableFrameWrapper frameData={frameData} isEditable={isEditable}>
      <AbsoluteFill
        style={{
          ...commonStyles,
          backgroundColor: bgColorObject["backgroundColor"],
        }}
      >
        <div style={wrapperStyle}>
          <div style={titleContainerStyle}>
            <USPtitle
              projectName={content.projectTitle}
              logoPath={content.image2}
              currentRemotionFrame={currentRemotionFrame}
              durationInFrames={durationInFrames}
              displayAnimations={displayAnimations}
              isEditable={isEditable}
              commonStyles={commonStyles}
            />
            <USPtext
              uspText={content.text1}
              currentRemotionFrame={currentRemotionFrame}
              durationInFrames={durationInFrames}
              displayAnimations={displayAnimations}
              isEditable={isEditable}
              commonStyles={commonStyles}
            />
          </div>
          {/*  we do not want to show features for the preview, just the first part of the frame (title+USP*/}
          {displayAnimations && (
            <div style={sideFeaturesStyle}>
              <SideFeatures
                content={content}
                currentRemotionFrame={currentRemotionFrame}
                durationInFrames={durationInFrames}
                displayAnimations={displayAnimations}
                isEditable={isEditable}
                commonStyles={commonStyles}
                bgColorObject={bgColorObject}
                editorState={editorState}
              />
            </div>
          )}
          <BrowserWindowWithVideo
            content={content}
            currentRemotionFrame={currentRemotionFrame}
            durationInFrames={durationInFrames}
            displayAnimations={displayAnimations}
            isEditable={isEditable}
            aspectRatio={aspectRatio}
          />
        </div>
      </AbsoluteFill>
    </EditableFrameWrapper>
  );
}
