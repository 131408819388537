import { AnalyticsEventTypes } from './analytics';

interface LogbeatEvent {
  event_name: string;
  appearance?: {
    color?: string;
    emoji?: string;
  };
  description?: string;
}

export function composeLogbeatEvent(
  eventTitle: AnalyticsEventTypes,
  data?: object
): LogbeatEvent {
  const event: LogbeatEvent = {
    event_name: eventTitle,
  };

  // Initialize appearance if needed
  if (needsAppearance(eventTitle)) {
    event.appearance = {};
  }

  // Handle financial events
  if (isFinancialEvent(eventTitle)) {
    event.appearance = {
      color: '#36BA3F',
      emoji: '💰'
    };
    if (data) {
      event.description = JSON.stringify(data, null, 2);
    }
  }

  // Handle error events
  if (isErrorEvent(eventTitle)) {
    event.appearance = {
      color: '#FF0048',
      emoji: '⚠️'
    };
    if (data) {
      event.description = JSON.stringify(data, null, 2);
    }
  }

  // Add emojis for important non-financial, non-error events
  addEventSpecificEmoji(event, eventTitle);

  return event;
}

function isFinancialEvent(eventTitle: string): boolean {
  const financialEvents = [
    'upgrade_from_free',
    'switch_plan',
    'upgrade_plan',
    'downgrade_plan'
  ];
  return financialEvents.includes(eventTitle.toLowerCase());
}

function isErrorEvent(eventTitle: string): boolean {
  return eventTitle.toLowerCase().endsWith('_error');
}

function needsAppearance(eventTitle: string): boolean {
  return isFinancialEvent(eventTitle) || 
         isErrorEvent(eventTitle) || 
         hasSpecificEmoji(eventTitle);
}

function hasSpecificEmoji(eventTitle: string): boolean {
  const eventsWithEmojis = [
    'create_new_video',
    'render_video_done',
    'url_to_video_complete',
    'create_new_blank_video',
    'login_with_email',
    'signup'
  ];
  return eventsWithEmojis.includes(eventTitle.toLowerCase());
}

function addEventSpecificEmoji(event: LogbeatEvent, eventTitle: string): void {
  const emojiMap: { [key: string]: string } = {
    'create_new_video': '🎥',
    'render_video_done': '✨',
    'url_to_video_complete': '🔄',
    'create_new_blank_video': '📝',
    'login_with_email': '📧',
    'signup': '👋'
  };

  const lowercaseEvent = eventTitle.toLowerCase();
  if (emojiMap[lowercaseEvent]) {
    event.appearance = event.appearance || {};
    event.appearance.emoji = emojiMap[lowercaseEvent];
  }
} 