import React, { useMemo } from "react";
import { AbsoluteFill, interpolate, spring } from "remotion";
import { Editable } from "../../../FrameContentEditors/EditableFrameWrapper";
import { calculateFontSize } from "../../../remotionHelpers/calculateFontSize";
import { FPS } from "../../../remotionConstant";

interface ProjectTitleProps {
  currentRemotionFrame: number;
  durationInFrames: number;
  content: any;
  isEditable: boolean;
  displayAnimations: boolean;
  commonStyles: any;
}

export const ProjectTitle: React.FC<ProjectTitleProps> = ({
  currentRemotionFrame,
  durationInFrames,
  content,
  isEditable,
  displayAnimations,
  commonStyles,
}) => {
  const projectTitleDuration = durationInFrames;

  const progress = spring({
    fps: FPS,
    frame: currentRemotionFrame,
    config: {
      damping: 200,
    },
    durationInFrames: projectTitleDuration,
  });

  const rightStop = interpolate(progress, [0, 1], [200, 0]);
  const leftStop = Math.max(0, rightStop - 60);
  const maskImage = `linear-gradient(-45deg, transparent ${leftStop}%, black ${rightStop}%)`;

  const fadeOutStart = projectTitleDuration - 10;
  const opacity = interpolate(
    currentRemotionFrame,
    [fadeOutStart, projectTitleDuration],
    [1, 0],
    {
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
    }
  );

  const containerStyle: React.CSSProperties = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 1,
    }),
    []
  );
  const contentStyle: React.CSSProperties = useMemo(
    () => ({
      opacity,
      maskImage: displayAnimations ? maskImage : undefined,
      WebkitMaskImage: displayAnimations ? maskImage : undefined,
    }),
    [displayAnimations, maskImage]
  );
  const titleStyle: React.CSSProperties = useMemo(
    () => ({
      // fontSize: `${calculateFontSize(content.text1?.value?.length || 0)}em`,
      fontSize: `${calculateFontSize(
        content.projectTitle?.value?.length || 0,
        [3, 8, 16, 20],
        [13, 9, 8, 5]
      )}em`,
      fontWeight: 600,
      textAlign: "center",
      letterSpacing: "0.02em",
      lineHeight: 1.2,
      marginTop: "0.3em",
    }),
    [commonStyles, content.projectTitle?.value]
  );

  // function calculateFontSize(textLength: number): number {
  //   const minFontSize = 4;
  //   const maxFontSize = 13;
  //   const baseFontSize = 9;
  //   const referenceLengths = [3, 8, 16, 20];
  //   const referenceSizes = [13, 9, 8, 5];
  //
  //   const clampedLength = Math.min(
  //     Math.max(textLength, referenceLengths[0]),
  //     referenceLengths[referenceLengths.length - 1]
  //   );
  //
  //   let fontSize = baseFontSize;
  //
  //   for (let i = 0; i < referenceLengths.length - 1; i++) {
  //     const startLength = referenceLengths[i];
  //     const endLength = referenceLengths[i + 1];
  //     const startSize = referenceSizes[i];
  //     const endSize = referenceSizes[i + 1];
  //
  //     if (clampedLength >= startLength && clampedLength <= endLength) {
  //       const ratio = (clampedLength - startLength) / (endLength - startLength);
  //       fontSize = startSize + (endSize - startSize) * Math.pow(ratio, 1.5);
  //       break;
  //     }
  //   }
  //
  //   return Math.round(Math.max(minFontSize, Math.min(maxFontSize, fontSize)));
  // }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Editable className="frame__editable_content--normal-size" isEditable={isEditable} content={content.projectTitle} hint="Your project's name.">
          <h1 style={titleStyle} className={""}>
            {content.projectTitle?.value || "Default Text"}
          </h1>
        </Editable>
      </div>
    </div>
  );
};
