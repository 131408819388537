import React, { useState, useRef, useEffect } from "react";
import {
  Popover,
  Box,
  Button,
  Progress,
  Text,
  Blockquote,
} from "@mantine/core";
import {
  IconDatabaseSmile,
  IconInfoCircle,
  IconUpload,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFetcher, useRouteLoaderData } from "@remix-run/react";
import { RootLoaderData } from "~/root";
import { useEditorState } from "../../AppContent/state/EditorStateContext";
import { handleFileChange } from "./handleFileChange";
import { handleUpload } from "./handleUpload";

interface FrameImageEditorProps {
  content: any;
  isEditable: boolean;
  handleContentChange: (newValue: string) => void;
  children: React.ReactNode;
  style?: any;
  hint?: string;
  className?: string;
}

export function FrameImageAndVideoUploader({
  content,
  isEditable,
  handleContentChange,
  children,
  style,
  hint,
  className,
}: FrameImageEditorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { editorState } = useEditorState();

  const handleUploadComplete = async (data: any) => {
    if (data.success) {
      await handleUpload(
        data,
        fileInputRef,
        setUploadProgress,
        (publicUrl) => {
          handleContentChange(publicUrl);
          setIsUploading(false);
          setIsOpen(false);
        }
      );
    }
    setIsUploading(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle") {
      handleUploadComplete(fetcher.data);
    }
  }, [fetcher]);

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  let acceptFilesString = "image/*";
  if (content.type === "image") {
    acceptFilesString = "image/*";
  } else if (content.type === "video") {
    acceptFilesString = "video/*";
  }

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileChange({
        file,
        uploadType: content.type,
        editorState,
        setIsUploading,
        setUploadProgress,
        fetcher
      });
    }
  };

  return (
    <Popover
      opened={!isEditable ? false : isOpen}
      onChange={setIsOpen}
      width={300}
      position="left"
      withArrow
      trapFocus
      transitionProps={{ duration: 0 }}
      offset={20}
    >
      <Popover.Target>
        <Box
          className={`frame__editable_content ${className ? className : ""}`}
          onClick={() => setIsOpen((o) => !o)}
          style={{ ...style }}
        >
          {/*<div className="frame__editable_content_border"></div>*/}
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        {isUploading ? (
          <>
            <Text size="sm" mb="xs">
              {t("Uploading...")}
            </Text>
            <Progress value={uploadProgress} mb="md" />
          </>
        ) : (
          <>
            {hint && (
              <Text size={"sm"} c="dimmed" mb={"xs"}>
                {hint}
              </Text>
            )}

            <Button
              leftSection={<IconUpload size="1rem" />}
              onClick={openFileDialog}
              fullWidth
            >
              {t("Upload new")}
            </Button>
          </>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={acceptFilesString}
          onChange={onFileChange}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
